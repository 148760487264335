import style from './userdetail.module.scss';
import { PageWrapper, MultiplayerCard, SkeletonCard } from '../../components';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { WorkCard } from '../../components';
import UserDemo from '../../assets/img/demo-user.png';
import {
  UserEditIcon,
  ShareIcon,
  FacebookIcon,
  XIcon,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
  GithubIcon,
  PinterestIcon,
  MediumIcon,
  FigmaIcon,
  DribbleIcon,
  TiktokIcon,
  SpotifyIcon,
  BehanceIcon,
  WebsiteIcon,
} from '../../assets/icon';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useLocation } from 'react-router-dom';
import { PopupWork, Spinner } from '../../components';
import useWindowDimensions from '../../helpers/windowSize.jsx';
import { useNavigate } from 'react-router-dom';

const SocialMediaData = [
  {
    title: 'Website',
    icon: <WebsiteIcon />,
    slug: 'website',
    link: '',
  },
  {
    title: 'Facebook',
    icon: <FacebookIcon />,
    slug: 'facebook',
    link: 'https://www.facebook.com/',
  },
  {
    title: 'X',
    icon: <XIcon />,
    slug: 'x',
    link: 'https://twitter.com/',
  },
  {
    title: 'Instagram',
    icon: <InstagramIcon />,
    slug: 'instagram',
    link: 'https://instagram.com/',
  },
  {
    title: 'LinkedIn',
    icon: <LinkedinIcon />,
    slug: 'linkedin',
    link: 'https://www.linkedin.com/',
  },
  {
    title: 'Youtube',
    icon: <YoutubeIcon />,
    slug: 'youtube',
    link: 'https://www.youtube.com/',
  },
  {
    title: 'Github',
    icon: <GithubIcon />,
    slug: 'github',
    link: 'https://www.github.com/',
  },
  {
    title: 'Pinterest',
    icon: <PinterestIcon />,
    slug: 'pinterest',
    link: 'https://www.pinterest.com/',
  },
  {
    title: 'Medium',
    icon: <MediumIcon />,
    slug: 'medium',
    link: 'https://medium.com/',
  },
  {
    title: 'Figma',
    icon: <FigmaIcon />,
    slug: 'figma',
    link: '',
  },
  {
    title: 'Dribbble',
    icon: <DribbleIcon />,
    slug: 'dribbble',
    link: 'https://dribbble.com/',
  },
  {
    title: 'Tiktok',
    icon: <TiktokIcon />,
    slug: 'tiktok',
    link: 'https://tiktok.com/',
  },
  {
    title: 'Spotify',
    icon: <SpotifyIcon />,
    slug: 'spotify',
    link: 'https://spotify.com/',
  },
  {
    title: 'Behance',
    icon: <BehanceIcon />,
    slug: 'behance',
    link: 'https://behance.com/',
  },
];

const UserDetail = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[2];
  const [selected, setSelected] = useState();
  const [isLoad, setLoad] = useState(true);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(`${ApiRoutes.user.get}`, { path: currentLocation })
      .then(res => {
        setData(res.data[0]);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper activeHeader popupActive={selected}>
      {/* {isLoad && <Spinner />} */}
      {selected && data && (
        <PopupWork
          image={selected.original}
          title={selected.title}
          navigateStatus={
            selected._id === data.workList[0]._id
              ? 'notBefore'
              : selected._id === data.workList[data.workList.length - 1]._id
              ? 'notAfter'
              : 'both'
          }
          userId={{
            name: selected.name,
            surname: selected.surname,
            photo: selected.photo,
            userId: selected.userId,
            userTitle: selected.userTitle,
          }}
          closeClick={() => setSelected()}
          type={'single'}
          afterClick={() => {
            if (selected._id !== data.workList[data.workList.length - 1]._id) {
              const findIndex = data.workList.findIndex(
                i => i._id === selected._id
              );
              console.log(findIndex);
              console.log(data.length);
              setSelected({
                ...data.workList[findIndex + 1],
                name: data.name,
                surname: data.surname,
                photo: data.photo,
                userTitle: data.title,
                userId: data._id,
              });
            }
          }}
          beforeClick={() => {
            if (selected._id !== data.workList[0]._id) {
              const findIndex = data.workList.findIndex(
                i => i._id === selected._id
              );
              console.log(findIndex);
              console.log(data.length);
              setSelected({
                ...data.workList[findIndex - 1],
                name: data.name,
                surname: data.surname,
                photo: data.photo,
                userTitle: data.title,
                userId: data._id,
              });
            }
          }}
          detailHide
        />
      )}
      {data && (
        <div className={style.categories}>
          <div className={style.info}>
            {width > 960 ? (
              <>
                <h1>{data.name + ' ' + data.surname}</h1>
                <h2>{data.title}</h2>
                <h4>{`MCS-${data.mcsId}`}</h4>
                <img src={data.photo} />
                <div className={style.tagWrapper}>
                  <div className={style.tagGrid}>
                    {data.tags.map(item => {
                      return (
                        <a href={`/search?word=${item.title}`}>{item.title}</a>
                      );
                    })}
                  </div>
                </div>
                <p>{data.description}</p>
              </>
            ) : (
              <div className={style.mobileInfo}>
                <div className={style.infoLeft}>
                  <img src={data.photo} />
                  <div className={style.tagWrapper}>
                    <div className={style.tagGrid}>
                      {data.tags.map(item => {
                        return (
                          <a href={`/search?word=${item.title}`}>
                            {item.title}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={style.infoRight}>
                  <h1>{data.name + ' ' + data.surname}</h1>
                  <h2>{data.title}</h2>
                  <h4>{`MCS-${data.mcsId}`}</h4>
                  <p>{data.description}</p>
                </div>
              </div>
            )}
            <div className={style.buttonWrapper}>
              {/* <button>
                <UserEditIcon /> Düzenle
              </button> */}
              {data.socialMedia.map(item => {
                const currentSocialData = SocialMediaData.filter(
                  i => i.title === item.title
                )[0];

                return (
                  <a
                    target={'_blank'}
                    href={`${currentSocialData.link}${item.link}`}
                  >
                    {currentSocialData.icon} <h5>{item.title}</h5>
                  </a>
                );
              })}
            </div>
          </div>
          <div className={style.right}>
            {data.headWork && data.headWork.length ? (
              <div className={style.headWrapper}>
                {data.headWork.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: `${
                          width > 960 ? item.height : item.height / 1.3
                        }px`,
                      }}
                    >
                      <WorkCard
                        image={item.preview}
                        title={item.title}
                        index={index}
                        click={() => {
                          setSelected({
                            ...item,
                            name: data.name,
                            surname: data.surname,
                            photo: data.photo,
                            userTitle: data.title,
                            userId: data._id,
                          });
                        }}
                        notClick={item.title.includes('none-click')}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className={style.gridWrapper}>
              <Box
                className={style.box}
                sx={{ width: '100%', padding: '0 0px', minHeight: 393 }}
              >
                <Masonry
                  className={style.masonry}
                  columns={{ xs: 2, sm: 3 }}
                  spacing={{ xs: 2, sm: 4 }}
                >
                  {data.workList.map((item, index) => (
                    <div
                      style={{
                        height: `${
                          width > 960 ? item.height : item.height / 1.3
                        }px`,
                      }}
                    >
                      <WorkCard
                        title={item.title}
                        image={item.preview}
                        index={index}
                        click={() => {
                          setSelected({
                            ...item,
                            name: data.name,
                            surname: data.surname,
                            photo: data.photo,
                            userTitle: data.title,
                            userId: data._id,
                          });
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
              </Box>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default UserDetail;
