import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  let auth = localStorage.getItem('token');
  const navigate = useNavigate();
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log('logout');
    window.location.replace('/login');
  }

  return children;
};
