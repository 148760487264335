import style from './user.module.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { WorkCard, Spinner, PopupWork } from '../../components';
import { UserShareIcon, Logo } from '../../assets/icon';

const UserShare = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  const [isLoad, setLoad] = useState(true);
  const [selected, setSelected] = useState();

  useEffect(() => {
    axios
      .post(`${ApiRoutes.user.get}`, { path: currentLocation })
      .then(res => {
        setData(res.data[0]);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={style.userShare}>
      {isLoad && <Spinner />}

      <div className={`${style.popupBackground} ${selected && style.active}`} />

      {selected && data && (
        <PopupWork
          image={selected.original}
          title={selected.title}
          navigateStatus={
            selected._id === data.workList[0]._id
              ? 'notBefore'
              : selected._id === data.workList[data.workList.length - 1]._id
              ? 'notAfter'
              : 'both'
          }
          userId={{
            name: selected.name,
            surname: selected.surname,
            photo: selected.photo,
            userId: selected.userId,
            userTitle: selected.userTitle,
          }}
          closeClick={() => setSelected()}
          type={'single'}
          afterClick={() => {
            if (selected._id !== data.workList[data.workList.length - 1]._id) {
              const findIndex = data.workList.findIndex(
                i => i._id === selected._id
              );
              console.log(findIndex);
              console.log(data.length);
              setSelected({
                ...data.workList[findIndex + 1],
                name: data.name,
                surname: data.surname,
                photo: data.photo,
                userTitle: data.title,
                userId: data._id,
              });
            }
          }}
          beforeClick={() => {
            if (selected._id !== data.workList[0]._id) {
              const findIndex = data.workList.findIndex(
                i => i._id === selected._id
              );
              console.log(findIndex);
              console.log(data.length);
              setSelected({
                ...data.workList[findIndex - 1],
                name: data.name,
                surname: data.surname,
                photo: data.photo,
                userTitle: data.title,
                userId: data._id,
              });
            }
          }}
        />
      )}
      <div className={style.svgWrapper}>
        <UserShareIcon />
      </div>
      {data && (
        <div className={style.wrapper}>
          <a href="/home">
            <Logo />
          </a>
          <div className={style.info}>
            <img src={data.photo} className={style.photo} />
            <div className={style.title}>
              <h1>
                {data.name} {data.surname}
              </h1>
              <h2>{data.title}</h2>
            </div>
            <div className={style.tagWrapper}>
              {data.tags.map(item => {
                return <div className={style.tag}>{item.title}</div>;
              })}
            </div>
          </div>
          <div className={style.gridWrapper}>
            <Box sx={{ width: '100%', minHeight: 393 }}>
              <Masonry className={style.masonry} columns={4} spacing={4}>
                {data.workList.map((item, index) => (
                  <div
                    style={{
                      height: `${item.height}px`,
                    }}
                  >
                    <WorkCard
                      title={item.title}
                      image={item.preview}
                      index={index}
                      click={() => {
                        setSelected({
                          ...item,
                          name: data.name,
                          surname: data.surname,
                          photo: data.photo,
                          userTitle: data.title,
                          userId: data._id,
                        });
                      }}
                    />
                  </div>
                ))}
              </Masonry>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserShare;
