import {
  Home,
  Login,
  Categories,
  CategoryDetail,
  WorkDetail,
  UserDetail,
  EmptySearch,
  Works,
  Faq,
  Maintance,
  UserShare,
} from '../containers';

export const routes = [
  // {
  //   title: 'Home',
  //   path: '*',
  //   component: <Maintance />,
  //   protected: false,
  // },

  {
    title: 'Home',
    path: '*',
    component: <Home />,
    protected: false,
  },

  {
    title: 'Categories',
    path: '/categories',
    component: <CategoryDetail />,
    protected: false,
  },
  {
    title: 'Category Detail',
    path: '/categories-detail',
    component: <CategoryDetail />,
    protected: false,
  },
  {
    title: 'Work Detail',
    path: '/work/:id',
    component: <WorkDetail />,
    protected: false,
  },
  {
    title: 'User Detail',
    path: '/user/:id',
    component: <UserDetail />,
    protected: false,
  },
  {
    title: 'Empty Search',
    path: '/search',
    component: <EmptySearch />,
    protected: false,
  },
  {
    title: 'Works',
    path: '/works',
    component: <Works />,
    protected: false,
  },
  {
    title: 'Faq',
    path: '/faq',
    component: <Faq />,
    protected: false,
  },
  {
    title: 'User Share',
    path: '/:userId',
    component: <UserShare />,
    protected: false,
  },
];
