import { useEffect, useState } from 'react';
import style from './header.module.scss';
import { LogoIcon, SearchIcon } from '../../assets/icon';
import { SearchCard, SearchWorkCard, SearchUserCard } from '../';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addRecent, deleteRecent } from '../../redux/actions';

const Header = ({ activeHeader, popupActive, scrollTop }) => {
  const [isFocus, setFocus] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(useLocation().search);
  const searchWord = params.get('word');
  const [currentWord, setCurrentWord] = useState('');
  const dispatch = useDispatch();
  const recentSearch = useSelector(state => state.search.recents);

  const searchNavigate = value => {
    dispatch(addRecent(value));
    navigate(`/search?word=${value}`);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.target.value) {
      searchNavigate(event.target.value);
    }
  };

  console.log('isFocus', isFocus);

  return (
    <>
      <header
        className={`${style.header} ${
          (scrollTop > 50 || activeHeader) && style.active
        } ${popupActive && style.active} ${popupActive && style.popupActive}`}
      >
        <div className={style.left}>
          <a className={style.logo} href="/">
            <LogoIcon />
          </a>
          <ul>
            <li>
              <a href="/works">m-works</a>
            </li>
            <li>
              <a href="/categories">m-humans</a>
            </li>
          </ul>
        </div>
        <label
          onClick={() => {
            setFocus(true);
          }}
          className={`${isFocus && style.focus} ${
            !recentSearch || (!recentSearch.length && style.focusWithoutRecent)
          }`}
        >
          <div className={style.labelWrapper}>
            {isFocus && (
              <input
                type={'text'}
                placeholder="Search for solution"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyDown={handleKeyDown}
                defaultValue={searchWord}
                onChange={e => {
                  setCurrentWord(e.target.value);
                }}
              />
            )}
            <button
              onClick={() => {
                setFocus(true);

                if (currentWord) {
                  searchNavigate(currentWord);
                  setFocus(false);
                }
              }}
            >
              <SearchIcon />
            </button>
          </div>
          {recentSearch && recentSearch.length ? (
            <div className={style.recent}>
              <h5>Recent Searches</h5>
              <div className={style.cardWrapper}>
                {recentSearch.slice(0, 4).map(item => {
                  return (
                    <SearchCard
                      deleteClick={() => {
                        dispatch(deleteRecent(item));
                      }}
                      searchClick={() => {
                        searchNavigate(item);
                      }}
                      title={item}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {/* <div className={style.recentBottom}>
            <div className={style.recent}>
              <h5>Multi Works</h5>
              <div className={style.cardWrapper}>
                <SearchWorkCard title={'İdaş'} image={WorkDemo1} />
                <SearchWorkCard title={'LOTTO'} image={WorkDemo4} />
              </div>
            </div>
            <div className={style.recent}>
              <h5>Multi Humans</h5>
              <div className={style.cardWrapper}>
                <SearchUserCard
                  title={'Sibel Lorem'}
                  subTitle="UI / UX"
                  image={UserDemo}
                />
                <SearchUserCard
                  title={'Ayşe Lorem'}
                  subTitle="Colorist"
                  image={UserDemo}
                />
              </div>
            </div>
          </div> */}
        </label>
        {isFocus && (
          <button
            onClick={() => {
              setFocus(false);
            }}
            className={style.shadowButton}
          />
        )}
      </header>
    </>
  );
};

export default Header;
