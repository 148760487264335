import style from './emptysearch.module.scss';
import { PageWrapper, WorkCard, UserCard, Spinner } from '../../components';
import { DeleteIcon } from '../../assets/icon';
import Masonry from '@mui/lab/Masonry';

import MultiWork1 from '../../assets/img/multi-work-1.png';
import MultiWork2 from '../../assets/img/multi-work-2.png';
import MultiWork3 from '../../assets/img/multi-work-3.png';
import MultiWork4 from '../../assets/img/multi-work-4.png';

import UserDemo from '../../assets/img/demo-user.png';

import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import useWindowDimensions from '../../helpers/windowSize.jsx';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const heights = [...new Array(100)].map(item => {
  const currentHeight = randomIntFromInterval(10, 60);
  return parseFloat(`${currentHeight}0`);
});

const EmptySearch = () => {
  const [searchData, setSearchData] = useState();
  const [searchWorks, setSearchWorks] = useState();
  const params = new URLSearchParams(useLocation().search);
  const searchWord = params.get('word');
  const [isLoad, setLoad] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (searchWord) {
      setLoad(true);
      setSearchData();
      setSearchWorks();
      axios
        .post(`${ApiRoutes.search.get}`, { word: searchWord })
        .then(res => {
          console.log(res.data);
          setSearchData(res.data);
          setSearchWorks(
            [
              ...res.data.work.map(item => {
                return item.workList.map(i => {
                  return {
                    ...i,
                    path: item.path,
                  };
                });
              }),
              ...res.data.user.map(item => {
                return item.workList.map(i => {
                  return {
                    ...i,
                    path: item.path,
                    workType: 'userWork',
                  };
                });
              }),
            ].flat()
          );
          setLoad(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [searchWord]);

  console.log(searchWorks);

  return (
    <PageWrapper>
      <div className={style.search}>
        {searchData && searchWorks ? (
          <>
            <div className={style.searchResult}>
              <h3 className={style.resultTitle}>
                Search Results <span>"{searchWord}"</span>
              </h3>
            </div>
            <div className={style.searchResultWrapper}>
              {searchData.user.length ? (
                <div
                  style={{
                    width: searchData.user.length > 1 ? '50%' : '20%',
                  }}
                  className={style.userResultWrapper}
                >
                  <h2 className={style.searchTitle}>Multi Humans</h2>
                  <div className={style.searchMultiGrid}>
                    {searchData.user.map(item => {
                      return (
                        <UserCard
                          title={item.name + ' ' + item.surname}
                          subTitle={item.title}
                          image={item.photo}
                          tags={item.tags}
                          id={`MCS-${item.mcsId}`}
                          path={item.path}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {searchWorks && searchWorks.length ? (
                <div className={style.workResultWrapper}>
                  <h2 className={style.searchTitle}>Multi Works</h2>
                  <div className={style.workWrapper}>
                    <Masonry
                      className={style.masonry}
                      columns={{ xs: 2, sm: 3 }}
                      spacing={{ xs: 2, sm: 4 }}
                    >
                      {searchWorks.map((item, index) => {
                        console.log(item);
                        return (
                          <div
                            style={{
                              height: `${
                                width > 960
                                  ? item.height * 1.5
                                  : item.height / 2
                              }px`,
                            }}
                          >
                            <WorkCard
                              path={
                                item.workType
                                  ? `/user/${item.path}`
                                  : `/work/${item.path}`
                              }
                              image={item.preview}
                              index={index}
                              title={item.title}
                              home
                            />
                          </div>
                        );
                      })}
                    </Masonry>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : isLoad ? null : (
          <>
            <div className={style.top}>
              <div className={style.recent}>
                <h2>Recent Searches</h2>
                <div className={style.recentGrid}>
                  <span>
                    stillife
                    <button>
                      <DeleteIcon />
                    </button>
                  </span>
                  <span>
                    sports
                    <button>
                      <DeleteIcon />
                    </button>
                  </span>
                </div>
              </div>
              <div className={style.bottom}>
                <div className={style.multiWorks}>
                  <h2>Multi Works</h2>
                  <div className={style.multiGrid}>
                    <button>
                      <h5>İdaş</h5>
                      <img src={MultiWork1} />
                    </button>
                    <button>
                      <h5>InStreet</h5>
                      <img src={MultiWork2} />
                    </button>
                    <button>
                      <h5>MACBX</h5>
                      <img src={MultiWork3} />
                    </button>
                    <button>
                      <h5>LOTTO</h5>
                      <img src={MultiWork4} />
                    </button>
                  </div>
                </div>
                <div className={style.multiHumans}>
                  <h2>Multi Humans</h2>
                  <div className={style.multiGrid}>
                    <UserCard
                      title={'Sibel Lorem'}
                      subTitle="UI / UX"
                      image={UserDemo}
                      tags={['stillife', 'sports', 'lifestyle']}
                      id="MCS-2324"
                    />
                    <UserCard
                      title={'Ayşe Lorem'}
                      subTitle="Colorist"
                      image={UserDemo}
                      tags={['stillife', 'sports', 'lifestyle']}
                      id="MCS-2324"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.workWrapper}>
              <Masonry columns={4} spacing={4}>
                {heights.map((height, index) => (
                  <div
                    style={{
                      height: `${height}px`,
                    }}
                  >
                    <WorkCard home index={index} />
                  </div>
                ))}
              </Masonry>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default EmptySearch;
