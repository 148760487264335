import React, { useState, useRef, useEffect } from 'react';
import mainStyle from './workcard.module.scss';
import { useNavigate } from 'react-router-dom';

function WorkCard({
  index,
  image,
  path,
  title,
  click,
  home,
  mainTitle,
  notClick,
  id,
}) {
  const [bounds, setBounds] = useState(null);
  const cardRef = useRef();
  const glowRef = useRef();
  const navigate = useNavigate();

  function rotateToMouse(e) {
    if (!bounds || !cardRef.current || !glowRef.current) return; // bounds null ise fonksiyon işleme devam etme
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    const card = cardRef.current;
    card.style.transform = `
      scale3d(1, 1, 1)
      rotate3d(
        ${center.y / 50},
        ${-center.x / 50},
        0,
        ${Math.log(distance) * 1}deg
      )
    `;

    const glow = glowRef.current;
    if (glow) {
      glow.style.backgroundImage = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #ffffff55,
          #0000000f
        )
      `;
    }
  }

  function handleMouseEnter() {
    const cardElement = cardRef.current;
    setBounds(cardElement.getBoundingClientRect());
    cardRef.current.addEventListener('mousemove', rotateToMouse);
  }

  function handleMouseLeave() {
    cardRef.current.removeEventListener('mousemove', rotateToMouse);
    cardRef.current.style.transform = '';
    cardRef.current.style.background = '';
  }

  const type = image.split('.')[image.split('.').length - 1];

  return (
    <div
      className={`${mainStyle.card} ${home ? mainStyle.home : null}`}
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={id}
      onClick={() => {
        navigate(path);
        if (click && !notClick) {
          click();
        }
      }}
    >
      {type === 'mp4' ? (
        <video playsInline className={mainStyle.image} loop muted autoPlay>
          <source src={image} type="video/mp4" />
        </video>
      ) : (
        <img className={mainStyle.image} src={image} />
      )}
      <h5>{mainTitle}</h5>
      <div ref={glowRef} className={mainStyle.glow}></div>
    </div>
  );
}

export default WorkCard;
