import { ADD_RECENT_SEARCH, DELETE_RECENT_SEARCH } from '../type';

export const addRecent = payload => {
  return {
    type: ADD_RECENT_SEARCH,
    payload: payload,
  };
};

export const deleteRecent = payload => {
  return {
    type: DELETE_RECENT_SEARCH,
    payload: payload,
  };
};
