import style from './categorybar.module.scss';

const CategoryBar = ({ data, mouseEnter, active, isActive }) => {
  return (
    <div className={style.categoryBar}>
      <ul>
        {data.map((item, index) => {
          return (
            <li className={`${active === item && style.active}`}>
              <button
                onMouseEnter={() => {
                  mouseEnter(index);
                }}
                href={`/categories/${item.path}`}
                className={isActive === index && style.hover}
              >
                {item.title}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryBar;
