import style from './categoryline.module.scss';
import { useNavigate } from 'react-router-dom';

const CategoryLine = ({ data, mouseEnter, isActive, type, width }) => {
  const navigate = useNavigate();
  return (
    <div className={style.categoryLine}>
      <ul>
        {data.map((item, index) => {
          return (
            <li>
              <a
                onMouseEnter={() => {
                  if (width > 960) {
                    mouseEnter(index);
                  }
                }}
                onClick={() => {
                  if (width > 960) {
                    if (type === 'work') {
                      navigate(`/work/${item.path}`);
                    } else {
                      navigate('categories-detail');
                    }
                  } else {
                    if (isActive === index) {
                      if (type === 'work') {
                        navigate(`/work/${item.path}`);
                      } else {
                        navigate('categories-detail');
                      }
                    } else {
                      mouseEnter(index);
                    }
                  }
                }}
                className={isActive === index && style.active}
              >
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryLine;
