import style from './mobileheader.module.scss';
import { LogoIcon, SearchIcon, CloseIcon, MenuIcon } from '../../assets/icon';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const MobileHeader = ({ popupActive, activeHeader, scrollTop }) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [currentWord, setCurrentWord] = useState('');
  const params = new URLSearchParams(useLocation().search);
  const searchWord = params.get('word');

  const searchNavigate = value => {
    navigate(`/search?word=${value}`);
    setOpen(false);
    window.scroll(0, 0, 0);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.target.value) {
      searchNavigate(event.target.value);
    }
  };

  return (
    <header
      className={`${style.header} 
    ${(scrollTop > 50 || activeHeader) && style.active} ${
        (popupActive || isOpen) && style.active
      } ${popupActive && style.popupActive}
    ${isOpen ? style.menuActive : null}`}
    >
      <a className={style.logo} href="/">
        <LogoIcon />
      </a>

      <button onClick={() => setOpen(!isOpen)} className={style.menuButton}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
      <div className={style.menu}>
        <ul>
          <li>
            <a href="/works">m-works</a>
          </li>
          <li>
            <a href="/categories">m-humans</a>
          </li>
        </ul>
        <div className={style.labelWrapper}>
          <input
            type={'text'}
            placeholder="Search for solution"
            onKeyDown={handleKeyDown}
            defaultValue={searchWord}
            onChange={e => {
              setCurrentWord(e.target.value);
            }}
          />

          <button
            onClick={() => {
              if (currentWord) {
                searchNavigate(currentWord);
              }
            }}
          >
            <SearchIcon />
          </button>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
