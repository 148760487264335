import style from './main.module.scss';
import { DeleteIcon } from '../../assets/icon';

const SearchCard = ({ title, deleteClick, searchClick }) => {
  return (
    <div className={style.searchCard}>
      <div onClick={searchClick} className={style.shadowClick} />
      <p>{title}</p>
      <button onClick={deleteClick}>
        <DeleteIcon />
      </button>
    </div>
  );
};

export default SearchCard;
