import style from './style.module.scss';
import { CircleArrow, CircleClose } from '../../assets/icon';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';

const PopupWork = ({
  image,
  title,
  userId,
  closeClick,
  type,
  navigateStatus,
  afterClick,
  beforeClick,
  detailHide,
  path,
}) => {
  const [data, setData] = useState();
  const lengthVal = image.split('.').length;
  const visualType = image.split('.')[lengthVal - 1];

  console.log('visualType');

  useEffect(() => {
    if (type !== 'single') {
      // axios
      //   .post(`${ApiRoutes.user.getWithId}`, { id: userId })
      //   .then(res => {
      //     setData(res.data);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    }
  }, []);

  console.log('selected', path, type);

  return (
    <div className={style.popupWork}>
      <div className={style.wrapper}>
        <div className={style.center}>
          <div className={style.navigate}>
            <button
              className={navigateStatus === 'notBefore' && style.disabled}
              onClick={beforeClick}
            >
              <CircleArrow type="left" />
            </button>
            <button onClick={closeClick} className={style.close}>
              <CircleClose />
            </button>
            <button
              onClick={afterClick}
              className={navigateStatus === 'notAfter' && style.disabled}
            >
              <CircleArrow />
            </button>
          </div>
          {visualType === 'mp4' ? (
            <video
              playsInline
              key={title}
              className={style.mainImage}
              controls
              autoPlay
            >
              <source src={image} type="video/mp4" />
            </video>
          ) : (
            <img key={title} className={style.mainImage} src={image} />
          )}
        </div>
        <div className={style.bottom}>
          <div className={style.info}>
            <div className={style.title}>
              {/* <h3>Work Title</h3> */}
              <h4>{title}</h4>
            </div>

            {/* <div className={style.avatar}>
                <img src={type === 'single' ? userId.photo : data.photo} />
                <div>
                  <h5>
                    {type === 'single' ? userId.name : data.name}{' '}
                    {type === 'single' ? userId.surname : data.surname}
                  </h5>
                  <h6>{type === 'single' ? userId.userTitle : data.title}</h6>
                </div>
              </div> */}
          </div>
          {detailHide ? null : (
            <a
              href={`${type === 'single' ? `/user/${path}` : `/work/${path}`}`}
            >
              Review
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupWork;
