import style from './works.module.scss';
import { PageWrapper, CategoryLine, SkeletonCard } from '../../components';
import DemoGrid from '../../assets/img/demo-grid-category.png';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { WorkCard, Spinner } from '../../components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import useWindowDimensions from '../../helpers/windowSize.jsx';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const heights = [...new Array(100)].map(item => {
  const currentHeight = randomIntFromInterval(10, 60);
  return parseFloat(`${currentHeight}0`);
});

const Works = () => {
  const [data, setData] = useState();
  const [hoverData, setHoverData] = useState(0);
  const [isLoad, setLoad] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    axios
      .get(`${ApiRoutes.work.list}`)
      .then(res => {
        console.log(res.data);
        setData(res.data);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper>
      {/* {isLoad && <Spinner />} */}

      <div className={style.works}>
        {data && data.length ? (
          <CategoryLine
            data={data}
            mouseEnter={e => {
              console.log('hoverdata', e);
              setHoverData(e);
            }}
            width={width}
            isActive={hoverData}
            type={'work'}
          />
        ) : null}

        {data && data.length ? (
          <div className={style.gridWrapper}>
            {data[hoverData ? hoverData : 0].headWork &&
            data[hoverData ? hoverData : 0].headWork.length ? (
              <div className={style.headWrapper}>
                {data[hoverData ? hoverData : 0].headWork.map((item, index) => {
                  console.log('item', item);
                  return (
                    <div
                      style={{
                        height: `${
                          width > 960 ? item.height : item.height / 1.3
                        }px`,
                      }}
                    >
                      <WorkCard
                        image={item.preview}
                        title={item.title}
                        index={index}
                        id={item._id}
                        // click={() => {
                        //   setSelected(item);
                        // }}
                        path={`/work/${data[hoverData].path}`}
                        notClick={item.title.includes('none-click')}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
            <Box
              className={style.box}
              sx={{ width: '100%', padding: '0 70px', minHeight: 393 }}
            >
              <Masonry
                className={style.masonry}
                columns={{ xs: 2, sm: 3 }}
                spacing={{ xs: 2, sm: 4 }}
              >
                {data && data.length
                  ? data[hoverData ? hoverData : 0].workList.map(
                      (item, index) => {
                        console.log(item);
                        return (
                          <div
                            style={{
                              height: `${
                                width > 960 ? item.height : item.height / 1.3
                              }px`,
                            }}
                          >
                            <WorkCard
                              path={`/work/${data[hoverData].path}`}
                              image={item.preview}
                              index={index}
                              title={item.title}
                              id={item._id}
                            />
                          </div>
                        );
                      }
                    )
                  : [...new Array(20)].map((item, index) => {
                      return (
                        <div
                          style={{
                            height: `300px`,
                          }}
                          key={index}
                        >
                          <SkeletonCard />
                        </div>
                      );
                    })}
              </Masonry>
            </Box>
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default Works;
