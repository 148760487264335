import style from './main.module.scss';
import {
  LogoLinearSquare,
  LogoLinearMain,
  MiniSquareLinear,
} from '../../assets/icon';

const SearchUserCard = ({ title, subTitle, image }) => {
  return (
    <a href="/user/deneme" className={style.searchUserCard}>
      <img src={image} alt={title} />
      <div className={style.info}>
        <h3 className={style.title}>{title}</h3>
        <h4 className={style.subTitle}>{subTitle}</h4>
      </div>
      <LogoLinearSquare />
    </a>
  );
};

const WideUserCard = ({ title, subTitle, image, id, tags }) => {
  return (
    <div className={style.wideUserCard}>
      <img className={style.main} src={image} alt={title} />
      <img className={style.bg} src={image} alt={title} />
      <LogoLinearMain />
      <div className={style.info}>
        <div className={style.tagWrapper}>
          {tags.map(item => {
            return <button>{item}</button>;
          })}
        </div>
        <div className={style.titleWrapper}>
          <h5 className={style.id}>{id}</h5>
          <h3 className={style.title}>{title}</h3>
          <h4 className={style.subTitle}>{subTitle}</h4>
        </div>
      </div>
    </div>
  );
};

const MultiplayerCard = ({ title, image, name, path }) => {
  return (
    <a href={`/user/${path}`} className={style.multiplayerCard}>
      <img className={style.main} src={image} alt={title} />
      <div className={style.info}>
        <h3 className={style.title}>{name}</h3>
        <h4>{title}</h4>
      </div>
    </a>
  );
};

const UserCard = ({ title, subTitle, image, id, tags, path }) => {
  return (
    <a href={`/user/${path}`} className={style.userCard}>
      <img key={id} src={image} alt={title} />
      <div className={style.tagWrapper}>
        {tags &&
          tags.map(item => {
            return <button>{item.title}</button>;
          })}
      </div>
      <div className={style.info}>
        <h5>{id}</h5>
        <h3 className={style.title}>{title}</h3>
        <h4 className={style.subTitle}>{subTitle}</h4>
      </div>
      <LogoLinearSquare />
    </a>
  );
};

export { SearchUserCard, WideUserCard, MultiplayerCard, UserCard };
