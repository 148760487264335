import style from './mface.module.scss';
import MFaceLogo from '../../assets/img/m_face_logo.png';
import { useState } from 'react';

const MFace = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={`${style.mFace} ${isOpen ? style.active : null}`}>
      <div className={style.mFacePopup}>
        For creative solutions, say hello to{' '}
        <a target="__blank" href="mailto:alican@mcs.ist">
          alican@mcs.ist
        </a>{' '}
        and click <a href="/faq">FAQ</a> for questions you are curious about.
        Don't forget to follow us on{' '}
        <a
          target="__blank"
          href="https://www.instagram.com/multicreativesolutions/"
        >
          Instagram
        </a>
        .
      </div>
      <button onClick={() => setOpen(!isOpen)} className={style.mFaceButton}>
        <img src={MFaceLogo} />
      </button>
    </div>
  );
};

export default MFace;
