import { PageWrapper, SkeletonCard } from '../../components';
import DemoGrid from '../../assets/img/demo-grid.png';
import style from './home.module.scss';

import Box from '@mui/material/Box';

import Masonry from '@mui/lab/Masonry';
import { WorkCard, PopupWork, Spinner } from '../../components';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState, useEffect } from 'react';
import useWindowDimensions from '../../helpers/windowSize.jsx';

const Home = () => {
  const [data, setData] = useState();
  const [selected, setSelected] = useState();
  const [currentScroll, setCurrenScroll] = useState();
  const [openScroll, setOpenScroll] = useState();
  const [isLoad, setLoad] = useState(true);
  const { width } = useWindowDimensions();

  const onScroll = e => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolled = Math.floor((winScroll / height) * 100);
    setCurrenScroll(scrolled);
    // setScrollTop(scrolled);
  };

  useEffect(() => {
    // Fires when the document view has been scrolled
    window.addEventListener('scroll', onScroll);

    //
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    let totalData = [];
    let headTotalData = [];

    axios
      .get(`${ApiRoutes.work.list}?home=get`)
      .then(async res => {
        const workData = await res.data
          .map(item => {
            return item.workList.map(i => {
              return {
                ...i,
                path: item.path,
                mainTitle: item.title,
              };
            });
          })
          .flat();

        totalData = workData;

        const headData = await res.data
          .map(item => {
            return item.headWork
              .filter(i => !i.title.includes('none-click'))
              .map(i => {
                return {
                  ...i,
                  path: item.path,
                  mainTitle: item.title,
                  height: i.height / 2.5,
                };
              });
          })
          .flat();

        headTotalData = headData;

        await axios
          .get(`${ApiRoutes.category.list}`)
          .then(res => {
            const userData = res.data.categoryData
              .map(item => {
                return item.userList.map(i => {
                  return i.user.workList.map(work => {
                    console.log('work', i.user.workList);
                    return {
                      ...work,
                      name: i.user.name,
                      surname: i.user.surname,
                      photo: i.user.photo,
                      userId: i.user._id,
                      userTitle: i.user.title,
                      path: i.user.path,
                      mainTitle: work.title,
                    };
                  });
                });
              })
              .flat()
              .flat();

            const hash = {};
            const result = [...totalData, ...headTotalData, ...userData].filter(
              ({ _id }) => {
                var key = `${_id}`;
                if (!hash[key]) return (hash[key] = true);
              }
            );

            let shuffled = result
              .map(value => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value);

            setData(shuffled);
            setLoad(false);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper popupActive={selected}>
      {/* {isLoad && <Spinner />} */}
      {selected && data && (
        <PopupWork
          image={selected.original}
          title={selected.mainTitle}
          navigateStatus={
            selected._id === data[0]._id
              ? 'notBefore'
              : selected._id === data[data.length - 1]._id
              ? 'notAfter'
              : 'both'
          }
          userId={
            selected.type === 'single'
              ? {
                  name: selected.name,
                  surname: selected.surname,
                  photo: selected.photo,
                  userId: selected.userId,
                  userTitle: selected.userTitle,
                }
              : selected.userId
          }
          closeClick={() => {
            setSelected();
          }}
          type={selected.type}
          afterClick={() => {
            if (selected._id !== data[data.length - 1]._id) {
              const findIndex = data.findIndex(i => i._id === selected._id);
              console.log(findIndex);
              console.log(data.length);
              setSelected(data[findIndex + 1]);
            }
          }}
          beforeClick={() => {
            if (selected._id !== data[0]._id) {
              const findIndex = data.findIndex(i => i._id === selected._id);
              console.log(findIndex);
              console.log(data.length);
              setSelected(data[findIndex - 1]);
            }
          }}
          path={selected.path}
        />
      )}

      <div className={style.wrapper}>
        <Box
          className={style.box}
          sx={{ width: '100%', padding: '0 40px', minHeight: 393 }}
        >
          <Masonry
            className={style.masonry}
            columns={{ xs: 2, sm: 4 }}
            spacing={{ xs: 2, sm: 4 }}
          >
            {data
              ? data.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          height: `${
                            width > 960 ? item.height : item.height / 1.3
                          }px`,
                        }}
                      >
                        <WorkCard
                          // path={`/work/${data[hoverData].path}`}
                          image={item.preview}
                          index={index}
                          title={item.title}
                          mainTitle={item.mainTitle}
                          click={() => {
                            setSelected(item);
                          }}
                          home
                          id={item.title}
                        />
                      </div>
                    </>
                  );
                })
              : [...new Array(20)].map((item, index) => {
                  return (
                    <div
                      style={{
                        height: `300px`,
                      }}
                      key={index}
                    >
                      <SkeletonCard />
                    </div>
                  );
                })}
          </Masonry>
        </Box>
      </div>
    </PageWrapper>
  );
};

export default Home;
