import style from './workdetail.module.scss';
import { PageWrapper, MultiplayerCard } from '../../components';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { WorkCard, PopupWork, Spinner } from '../../components';
import UserDemo from '../../assets/img/demo-user.png';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import useWindowDimensions from '../../helpers/windowSize.jsx';

const WorkDetail = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[2];
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const [selected, setSelected] = useState();
  const [isLoad, setLoad] = useState(true);
  const { width } = useWindowDimensions();

  const onScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    axios
      .post(`${ApiRoutes.work.get}`, { path: currentLocation })
      .then(res => {
        setData(res.data[0]);
        const filterUser = res.data[0].userList.map(item => {
          return {
            name: item.userId.name + ' ' + item.userId.surname,
            title: item.userId.title,
            photo: item.userId.photo,
            path: item.userId.path,
          };
        });

        let uniqueChars = filterUser.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t => t.photo === value.photo && t.name === value.name
            )
        );
        setUserData(uniqueChars);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper activeHeader popupActive={selected}>
      {/* {isLoad && <Spinner />} */}
      {selected && data && (
        <PopupWork
          image={selected.original}
          title={data.title}
          navigateStatus={selected.status}
          userId={{
            name: selected.name,
            surname: selected.surname,
            photo: selected.photo,
            userId: selected.userId,
            userTitle: selected.userTitle,
          }}
          closeClick={() => setSelected()}
          type={'single'}
          afterClick={() => {
            const allData =
              data.headWork && data.headWork.length
                ? [
                    ...data.headWork.filter(
                      i => !i.title.includes('none-click')
                    ),
                    ...data.workList,
                  ]
                : data.workList;
            const currentData = allData.findIndex(i => i._id === selected._id);

            if (currentData !== allData.length - 1) {
              setSelected({
                ...allData[currentData + 1],
                status:
                  currentData + 1 === allData.length - 1 ? 'notAfter' : 'both',
              });
            }
          }}
          beforeClick={() => {
            const allData =
              data.headWork && data.headWork.length
                ? [
                    ...data.headWork.filter(
                      i => !i.title.includes('none-click')
                    ),
                    ...data.workList,
                  ]
                : data.workList;
            const currentData = allData.findIndex(i => i._id === selected._id);

            if (currentData !== 0) {
              setSelected({
                ...allData[currentData - 1],
                status: currentData - 1 === 0 ? 'notBefore' : 'both',
              });
            }
          }}
          detailHide
        />
      )}
      {data && userData && (
        <div className={style.categories}>
          <div className={style.info}>
            <h1>{data.title}</h1>
            <div className={style.tagWrapper}>
              <h2>#multitasking</h2>
              <div className={style.tagGrid}>
                {data.tags.map(item => {
                  return (
                    <a href={`/search?word=${item.title}`}>{item.title}</a>
                  );
                })}
              </div>
            </div>
            <div className={style.userWrapper}>
              <h2>#multiplayer</h2>
              <div className={style.userGrid}>
                {userData.map(item => {
                  return (
                    <MultiplayerCard
                      image={item.photo}
                      name={`${item.name}`}
                      title={`${item.title}`}
                      path={item.path}
                    />
                  );
                })}
              </div>
            </div>
            <div className={style.case}>
              <h2>#case</h2>
              <p>{data.description}</p>
            </div>
          </div>
          <div className={style.right}>
            {data.headWork && data.headWork.length ? (
              <div className={style.headWrapper}>
                {data.headWork.map((item, index) => {
                  console.log('item', item);
                  return (
                    <div
                      style={{
                        height: `${
                          width > 960 ? item.height : item.height / 1.3
                        }px`,
                      }}
                    >
                      <WorkCard
                        image={item.preview}
                        title={item.title}
                        index={index}
                        click={() => {
                          setSelected(item);
                        }}
                        notClick={item.title.includes('none-click')}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className={style.gridWrapper}>
              <Box
                className={style.box}
                sx={{ width: '100%', padding: '0 0px', minHeight: 393 }}
              >
                <Masonry
                  className={style.masonry}
                  columns={{ xs: 2, sm: 3 }}
                  spacing={{ xs: 2, sm: 4 }}
                >
                  {data.workList.map((item, index) => (
                    <div
                      style={{
                        height: `${
                          width > 960 ? item.height : item.height / 1.3
                        }px`,
                      }}
                    >
                      <WorkCard
                        image={item.preview}
                        title={item.title}
                        index={index}
                        click={() => {
                          setSelected(item);
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
              </Box>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default WorkDetail;
