import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/style/variables.module.scss';
import configureStore from './configureStore';
import { Provider } from 'react-redux';

export const store = configureStore();

export const state = store.getState();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
