import { ADD_RECENT_SEARCH, DELETE_RECENT_SEARCH } from '../type';

const initialState = {
  recents: [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RECENT_SEARCH:
      console.log(action.payload);
      return {
        ...state,
        recents: state.recents.includes(action.payload)
          ? state.recents
          : [action.payload, ...state.recents],
      };

    case DELETE_RECENT_SEARCH:
      return {
        ...state,
        recents: state.recents.filter(i => i !== action.payload),
      };
    default:
      return state;
  }
}
