import style from './login.module.scss';
import { Footer, WorkCard } from '../../components';
import { Logo } from '../../assets/icon';
import DemoGrid from '../../assets/img/demo-grid-login.png';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Masonry from '@mui/lab/Masonry';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { BaseUrl } from '../../utils/BaseUrl';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const heights = [...new Array(100)].map(item => {
  const currentHeight = randomIntFromInterval(10, 60);
  return parseFloat(`${currentHeight}0`);
});

const Login = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState('email');
  const inputRef = useRef();
  const [status, setStatus] = useState({
    type: '',
    text: '',
  });

  useEffect(() => {
    let totalData = [];

    axios
      .get(`/public/${ApiRoutes.work.list}`)
      .then(async res => {
        const workData = await res.data
          .map(item => {
            return item.workList.map(i => {
              return i;
            });
          })
          .flat();

        totalData = workData;

        await axios
          .get(`/public/${ApiRoutes.category.list}`)
          .then(res => {
            const userData = res.data
              .map(item => {
                return item.userList.map(i => {
                  return i.user.workList.map(work => {
                    return work;
                  });
                });
              })
              .flat()
              .flat();

            setData([...totalData, ...userData]);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const loginFunc = () => {
    setStatus({
      type: 'loading',
      text: '',
    });
    axios
      .post('/userAuth/login', {
        email: email,
      })
      .then(res => {
        if (res.data === 'backdoor') {
          localStorage.setItem('token', 'burhanaltintopback');
          axios.interceptors.request.use(
            async config => {
              // const token = await AsyncStorage.getItem('token');

              config.headers['x-access-token'] = 'burhanaltintopback';

              return config;
            },
            error => {
              return Promise.reject(error);
            }
          );
          navigate('/home');
          return;
        }
        setStatus('');
        console.log(res);
        inputRef.current.value = '';
        setStep('code');
      })
      .catch(err => {
        if (err.response.data) {
          setStatus({
            type: 'error',
            text: err.response.data,
          });
        } else {
          setStatus({
            type: '',
            text: '',
          });
        }
      });
  };

  const checkCode = () => {
    setStatus('loading');
    axios
      .post('/userAuth/checkCode', {
        email: email,
        code: code,
      })
      .then(res => {
        console.log(res);
        setStatus('');
        localStorage.setItem('token', res.data);

        axios.interceptors.request.use(
          async config => {
            // const token = await AsyncStorage.getItem('token');

            config.headers['x-access-token'] = res.data;

            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );

        navigate('/home');
      })
      .catch(err => {
        if (err.response.data) {
          setStatus({
            type: 'error',
            text: err.response.data,
          });
        } else {
          setStatus({
            type: '',
            text: '',
          });
        }
        console.log(err);
      });
  };

  return (
    <div className={style.login}>
      <div className={`${style.top} ${isOpen && style.topActive}`}>
        <Logo />
        <div className={style.info}>
          <h1>Hoş geldin!</h1>
          <p>
            Web sitemize erişmek için öncelikle e-posta adresinizi girmeniz
            gerekmektedir. Bu sayede, spam kullanıcıların erişimini önleyebilir
            ve size daha güvenli bir deneyim sunabiliriz.
          </p>
          <div className={style.buttonWrapper}>
            <button onClick={() => setOpen(true)}>e-posta'nı gir</button>
          </div>
        </div>
      </div>
      <div className={`${style.bottomForm} ${isOpen && style.active}`}>
        <div className={style.wrapper}>
          <h1>
            e-posta’nı gir,
            <br /> erişimi onayla,
            <br /> Multi’desin!
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dapibus
            porta risus id elementum. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit
          </p>
          {status.type === 'loading' ? (
            <div>
              <h1>loading...</h1>
            </div>
          ) : (
            <form>
              <label>
                <input
                  ref={inputRef}
                  onChange={e => {
                    if (step === 'email') {
                      setEmail(e.target.value);
                    } else {
                      setCode(e.target.value);
                    }
                  }}
                  type={step === 'email' ? 'email' : 'code'}
                  placeholder={step === 'email' ? 'e-posta' : 'kodu giriniz'}
                />
              </label>
              {status.type === 'error' ? (
                <span className={style.error}>{status.text}</span>
              ) : null}
              <button
                onClick={e => {
                  e.preventDefault();
                  if (step === 'email') {
                    loginFunc();
                  } else {
                    checkCode();
                  }
                  // navigate('/home');
                }}
              >
                {step === 'email' ? 'Kod Al' : 'Kodu Girin'}
              </button>
            </form>
          )}
        </div>
      </div>

      <div className={`${style.gridWrapper} ${isOpen && style.gridActive}`}>
        {data && (
          <Masonry columns={{ xs: 2, sm: 4 }} spacing={4}>
            {data.map((item, index) => (
              <div
                style={{
                  height: `${item.height}px`,
                  marginTop: index === 0 || index === 2 ? 100 : 'unset',
                }}
              >
                <WorkCard
                  // path={`/work/${data[hoverData].path}`}
                  image={item.preview}
                  index={index}
                />
              </div>
            ))}
          </Masonry>
        )}
      </div>
      <Footer isLogo={isOpen} />
    </div>
  );
};

export default Login;
