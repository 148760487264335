import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ScrollTop from './helpers/ScrollTop';
import axios from 'axios';
import { BaseUrl } from './utils/BaseUrl';
// import { Preloader } from './components';

export const history = createBrowserHistory();

const App = () => {
  // const [isLoad, setLoad] = useState(false);
  const [isLanguageLoad, setLanguageLoad] = useState(false);

  axios.defaults.baseURL = BaseUrl;
  const token = localStorage.getItem('token');
  axios.interceptors.request.use(
    async config => {
      // const token = await AsyncStorage.getItem('token');

      config.headers['x-access-token'] = token;

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('lang');

    if (!currentLanguage) {
      localStorage.setItem('lang', 'tr');
      setLanguageLoad(true);
    } else {
      setLanguageLoad(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', e => {
      console.log(e.message);
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        {/* {!isLoad && <Preloader />} */}

        <Routes>
          {isLanguageLoad &&
            routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute>{route.component}</ProtectedRoute>
                    ) : (
                      route.component
                    )
                  }
                />
              );
            })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
