import style from './categorydetail.module.scss';
import {
  PageWrapper,
  CategoryBar,
  WideUserCard,
  UserCard,
  Spinner,
} from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDemo from '../../assets/img/demo-user.png';
import Masonry from '@mui/lab/Masonry';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { slugify } from '../../helpers/slugify';

const CategoryDetail = () => {
  const location = useLocation();
  const currentCategory = location.pathname.split('/')[2];
  const [data, setData] = useState();
  const [hoverData, setHoverData] = useState(0);
  const [isLoad, setLoad] = useState(true);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.category.list}`)
      .then(res => {
        console.log(res.data);
        setData(
          res.data.categoryData.map(item => {
            return {
              ...item,
              path: slugify(item.title),
            };
          })
        );
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper>
      {/* {isLoad && <Spinner />} */}
      {data && (
        <div className={style.wrapper}>
          <CategoryBar
            mouseEnter={e => {
              setHoverData(e);
            }}
            isActive={hoverData}
            data={data}
            active={currentCategory}
          />
          <div className={style.masonryWrapper}>
            <Masonry
              className={style.masonry}
              columns={{ xs: 2, sm: 4 }}
              spacing={{ xs: 2, sm: 4 }}
            >
              {data[hoverData ? hoverData : 0].userList.map(item => {
                console.log(item);
                return (
                  <>
                    <UserCard
                      title={item.user.name + ' ' + item.user.surname}
                      tags={item.user.tags}
                      id={`MCS-${item.user.mcsId}`}
                      subTitle={item.user.title}
                      image={item.user.photo}
                      path={item.user.path}
                    />
                  </>
                );
              })}
            </Masonry>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default CategoryDetail;
