import style from './style.module.scss';

const Spinner = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.demo}>
        <div className={style.rings}>
          <div className={style.rings}>
            <div className={style.logo}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
