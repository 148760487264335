import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader.jsx';
import style from './pagewrapper.module.scss';
import MFace from '../MFace/MFace';
import useWindowDimensions from '../../helpers/windowSize.jsx';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageWrapper = ({ children, activeHeader, popupActive }) => {
  const { width } = useWindowDimensions();
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const location = useLocation();
  const currentLocation = location.pathname;

  const onScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={style.pageWrapper}>
      {currentLocation !== '/faq' ? <MFace /> : null}
      {width > 960 ? (
        <Header
          scrollTop={scrollTop}
          popupActive={popupActive}
          activeHeader={activeHeader}
        />
      ) : (
        <MobileHeader
          scrollTop={scrollTop}
          popupActive={popupActive}
          activeHeader={activeHeader}
        />
      )}
      {children}
    </div>
  );
};

export default PageWrapper;
