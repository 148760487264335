import { PageWrapper } from '../../components';
import style from './faq.module.scss';

import Box from '@mui/material/Box';

import Masonry from '@mui/lab/Masonry';
import { WorkCard, Spinner } from '../../components';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState, useEffect } from 'react';
import { PlusIcon } from '../../assets/icon';

const FaqCard = ({ title, description, isOn, click }) => {
  return (
    <div className={`${style.faqCard} ${isOn && style.active}`}>
      <div onClick={click} className={style.head}>
        <h3 onClick={click}> {title}</h3>
        <button onClick={click}>
          <PlusIcon />
        </button>
      </div>
      <p>{description}</p>
    </div>
  );
};

const Faq = () => {
  const [data, setData] = useState();
  const [selected, setSelected] = useState([]);
  const [isLoad, setLoad] = useState(true);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.faq.get}`)
      .then(async res => {
        setData(res.data);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <PageWrapper>
      {isLoad && <Spinner />}
      {data && (
        <div className={style.wrapper}>
          {data.map(item => {
            return (
              <FaqCard
                click={() => {
                  if (selected.includes(item._id)) {
                    setSelected(selected.filter(i => i !== item._id));
                  } else {
                    setSelected([...selected, item._id]);
                  }
                }}
                title={item.title}
                description={item.description}
                isOn={selected.includes(item._id)}
              />
            );
          })}
        </div>
      )}
    </PageWrapper>
  );
};

export default Faq;
