export const ApiRoutes = {
  work: {
    list: 'getWorkList',
    get: 'getWork',
  },
  category: {
    list: 'getCategoryList',
  },
  user: {
    get: 'getUser',
    getWithId: 'getUserWithId',
  },
  search: {
    get: 'search',
  },
  faq: {
    get: 'faq',
  },
};
